<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :searchControl="searchControl"
      :apiName="apiName"
      :editTitle="'巡检轨迹'"
    >
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      remote: api.common,
      apiName: "/meritPatrolTrack",
      searchVal: "",
      jobList: [],
      searchQuery: { trainName: "" },
      searchControl: {
        firstSoltSpan: [2, 2, 2, 2],
        secondSoltSpan: [2, 2, 2, 2],
        thirdSoltSpan: [18, 18, 18, 18],
      },
      props: [
        {
          label: "id",
          prop: "id",
          align: "center",
          width: "250px",
        },

        {
          label: "轨迹图片",
          prop: "mapImg",
          align: "center",
          width: "200px",
          type: "image",
        },

        {
          label: "创建时间",
          prop: "createTime",
          align: "center",
        },
      ],

      formProps: [
        {
          label: "轨迹图片",
          prop: "mapImg",
          type: "upload",
        },
      ],
    };
  },
  created() {},
  methods: {
    async getJobList() {
      await api.dictionary.getAllList({ type: "商品分类" }).then((res) => {
        this.formProps[2].selectData = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
